import React, { useState } from 'react';
import Header from './components/layout/header';
import { AppRoutes } from './Routes';
import { useMediaQuery } from 'react-responsive';
import './styles.css';
import './styles/responsive-desktop.css';
import './styles/responsive-mobile.css';
import './styles/index.css';
import './styles/dark.css';
import './styles/white.css';
import Footer from './components/layout/footer';
import BottomMenu from './components/layout/bottom-menu';



function App() {
  // Responsive
  const isTabletMobile = useMediaQuery({
    query: '(max-width: 1024px)'
  });


  const [darkMode, setDarkMode] = useState(true);




  
  return (
    <div className={`main-sec-all ${darkMode ? 'dark' : 'white'}`}>
      {isTabletMobile ? (
        // Responsive
        <div className={`px-4 pt-4 ${isTabletMobile ? 'h-screen' : 'h-screen'}`}>
          <div className="main-content gap-4 flex flex-col mobile-main-sec">
            <Header setDarkMode={setDarkMode} darkMode={darkMode} isTabletMobile={isTabletMobile} />
            <div className="h-full overflow-auto flex flex-col gap-4">
              <AppRoutes isTabletMobile={isTabletMobile} darkMode={darkMode} setDarkMode={setDarkMode} />
              <Footer />
            </div>
          </div>
          <div className='fixed bottom-4 main-content menu-mobile shadow-md z-50'>
            <BottomMenu />
          </div>
        </div>
      ) : (
        // Normal
        <div className="px-4 pt-4 flex flex-row gap-4 h-screen text-primary">
          <div className={`main-content flex gap-4 flex-col sidebar-header max-w-4xl m-[0_auto]`}>
            <Header setDarkMode={setDarkMode} darkMode={darkMode} isTabletMobile={isTabletMobile} />
            <div className="overflow-auto">
              <div className="basis-1/2 flex flex-col gap-4 h-full">
                <AppRoutes isTabletMobile={isTabletMobile} darkMode={darkMode} setDarkMode={setDarkMode} />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
