import React from 'react';
import { Link, useLocation } from 'react-router-dom';


export default function NotFoundPage({ }) {

    const location = useLocation(); 


    return (
        <div className="notes grid grid-cols-1 gap-4">
            <div className='w-full card'>
                <div>
                    <h1 className='text-base title-cinza mt-2 mb-2'>404,</h1>
                    <h1 className='text-2xl font-extrabold'>error</h1>
                </div>
            </div>
            <div className='card'>
                <div className="">
                    <Link to="/" className={`menu-item flex flex-col items-center ${location.pathname === '/' ? 'active' : ''}`}>
                        go to home
                    </Link>
                </div>
            </div>
        </div>
    );
}
