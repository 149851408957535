import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import logoDark from '../../assets/logo1.svg';
// import logoLight from '../../assets/logolight.svg';
// import logoiconDark from '../../assets/logoicon.svg';
import carloscostadevlogo from '../../assets/iconsite.png';
import { IoMdSunny, IoMdMoon } from 'react-icons/io';


export default function Header({ isTabletMobile, darkMode, setDarkMode }) {

    const location = useLocation();
    const isPlayerPage = location.pathname.startsWith('/transactions/');


    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };


    return (
        <div className="header">
            {isTabletMobile ? (
                // Responsive
                <div>
                    <div className="flex justify-center items-center justify-between">
                        <div className='logotipo-mobile'>
                            <Link to="/">
                                <img src={carloscostadevlogo} alt="CarlosCostaDev" />
                            </Link>
                        </div>
                        <div>
                            <button className='togglemodetheme' onClick={toggleDarkMode}>
                                {darkMode ? <IoMdSunny /> : <IoMdMoon />}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="card flex justify-center">
                    <div className="flex gap-4 justify-between items-center">

                        <div>
                            <nav>
                                <ul className="flex gap-4 justify-between items-center">
                                    <Link to="/">
                                        <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/' ? 'active' : ''}`}>
                                            home
                                        </li>
                                    </Link>
                                    <Link to="/projects">
                                        <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/projects' ? 'active' : ''}`}>
                                            projects
                                        </li>
                                    </Link>
                                    <Link to="/notes">
                                        <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/notes' ? 'active' : ''}`}>
                                            notes
                                        </li>
                                    </Link>
                                </ul>
                            </nav>
                        </div>
                        <div className=''>
                            <button className='togglemodetheme' onClick={toggleDarkMode}>
                                {darkMode ? <IoMdSunny /> : <IoMdMoon />}
                            </button>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
}



    // Normal
    // <div className="card">
    //     <div className="flex gap-4 justify-between items-center">
    //         <div>
    //             <div className=''>
    //                 <button className="toggle-sidebar-button icons-section-pai">
    //                     <Link to="/perfil"><BiUser className='icons-menu' /></Link>
    //                 </button>
    //             </div>
    //         </div>
    //     </div>
    // </div>