import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import Inicio from "./routes/inicio";
import Projects from './routes/projects';
import Notes from './routes/notes';
import NotFoundPage from './routes/notfound'; 

export function AppRoutes({ isTabletMobile, darkMode }) {
    return (
        <Routes>
            <Route path="/" element={<Inicio isTabletMobile={isTabletMobile} darkMode={darkMode} />} />
            <Route path="/projects" element={<Projects isTabletMobile={isTabletMobile} darkMode={darkMode} />} /> 
            <Route path="/notes" element={<Notes isTabletMobile={isTabletMobile} darkMode={darkMode} />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}
