import React from 'react';
import { Link } from 'react-router-dom';

function SkeletonLoader() {
  return (
    <div className='single-projeto grid gap-2.5 rounded-lg p-4 animate-pulse'>
      <div className='h-4 bg-neutral-200 dark:bg-neutral-700 w-3/4 mb-2 rounded-md'></div>
      <div className='h-4 bg-neutral-200 dark:bg-neutral-700 w-1/2 rounded-md'></div>
    </div>
  );
}

export default function Projects({ isTabletMobile }) {
  // Exemplo de dados em formato JSON
  const projectsData = [
    { id: 1, title: 'Emotional Objects', descricao: 'Luxury Furniture', image: '', link: 'https://www.behance.net/gallery/145169935/EMOTIONAL-OBJECTS-Luxury-Furniture-Website' },
    { id: 2, title: 'ComoeOnde', descricao: 'Forum Tips & Information', image: '', link: 'https://www.comoeonde.pt' },
    { id: 3, title: 'Meco+', descricao: 'Clinic Plataform', image: '', link: 'https://www.behance.net/gallery/165232571/MECO-Clinic-Plataform-Website-Design' },
    { id: 4, title: 'Doca da Marinha', descricao: 'Restaurants & Events', image: '', link: 'https://www.behance.net/gallery/160963887/DOCA-DA-MARINHA-LISBOA-Website-Design' },
    { id: 5, title: 'Pestana Residences', descricao: 'Real Estate and Residential', image: '', link: 'https://www.behance.net/gallery/183540701/PESTANA-RESIDENCES-Website-Design' },
    { id: 6, title: 'Gluing People', descricao: 'Collection of Cards', image: '', link: 'https://www.behance.net/gallery/184718693/GLUING-PEOPLE-Website-Design' },
    { id: 7, title: 'ZØR-MOR', descricao: 'Thermal Chaain', image: '', link: 'https://www.behance.net/gallery/145803723/ZOER-MOR' },
    { id: 8, title: 'TØR', descricao: 'Agency', image: '', link: 'https://www.behance.net/gallery/146670933/TOER-Studio-Agency' },
    { id: 9, title: 'Vision Volt', descricao: 'IoT Solutions', image: '', link: 'https://www.behance.net/gallery/162495149/VISION-VOLT-Website-Design-TECH' },

  ];

  return (
    <div className="projects card">
      <div className='w-full'>
        <div>
          <h1 className='text-base title-cinza mt-2 mb-2'>projects,</h1>
          <h1 className='text-2xl font-extrabold'>hard work</h1>
        </div>
        <br></br>
        <div className='grid gap-x-4 gap-y-6 sm:grid-cols-2 md:grid-cols-3'>
          {projectsData.map((project) => (
            <div className='flex flex-col border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
              <a href={project.link} target="_blank">
                <h1 className='text-md font-extrabold'>{project.title}</h1>
                <p>{project.descricao}</p>
              </a>
            </div>
          ))}
        </div>
        <br></br>
        <div className='flex justify-center'>
          <Link to="https://www.behance.net/carloscostapt99" target="_blank" rel="noopener noreferrer">
            <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium`}>
              see more
            </li>
          </Link>
        </div>
      </div>
    </div>
  );
}
