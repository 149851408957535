import React from 'react';


export default function Notes({ isTabletMobile, darkMode }) {


    return (
        <div className="notes grid grid-cols-1 gap-4">
            <div className='w-full card'>
                <div>
                    <h1 className='text-base title-cinza mt-2 mb-2'>notes,</h1>
                    <h1 className='text-2xl font-extrabold'>dev notepad</h1>
                </div>
            </div>
            <div className='card'>
                <div className="">
                    <p className='font-extrabold mb-2'>Soon</p>
                    <p>On this page, you will be able to find all the tips and pages that have been and are important for my journey, and I believe they will be for yours too!</p>
                </div>
            </div>
        </div>
    );
}
