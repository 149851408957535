import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BiLogoLinkedin, BiLogoBehance, BiSolidUser, BiCodeAlt, BiNote } from 'react-icons/bi';
import carloscostadevlogo from '../../assets/iconsite.png';

export default function BottomMenu({ darkMode }) {

  const location = useLocation();
  

  return (
    <nav className="flex p-4 justify-between rounded-lg menu-bottom-mobile">

      <Link to="/notes" className={`menu-item flex flex-col items-center p-2 ${location.pathname === '/notes' ? 'active' : ''}`}>
        <BiNote className='icon-menu' />
      </Link>
      <Link to="/projects" className={`menu-item flex flex-col items-center p-2 ${location.pathname === '/projects' ? 'active' : ''}`}>
        <BiCodeAlt className='icon-menu' />
      </Link>
      <Link to="/" className={`menu-item flex flex-col items-center ${location.pathname === '/' ? 'active' : ''}`}>
        <BiSolidUser className='icon-menu' /> 
      </Link>
      <a href="https://www.behance.net/carloscostapt99" target="_blank" rel="noopener noreferrer" className={`menu-item flex flex-col items-center p-2`}>
        <BiLogoBehance className='icon-menu' />
      </a>
      <a href="https://www.linkedin.com/in/carloscostaprogramador1999/" target="_blank" rel="noopener noreferrer" className={`menu-item flex flex-col items-center p-2`}>
        <BiLogoLinkedin className='icon-menu' />
      </a>
    </nav>
  );
}


