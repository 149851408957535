import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import reactI from '../assets/react.png';
import javascriptI from '../assets/js.png';
import typesenseI from '../assets/typesense.png';
import algoliaI from '../assets/algolia.png';
import htmlI from '../assets/html.png';
import cssI from '../assets/css-3.png';
import bootstrapI from '../assets/bootstrap.png';
import wordpressI from '../assets/wordpress.png';
import elementorI from '../assets/elementor.png';
import figmaI from '../assets/figma.png';
import photoshopI from '../assets/photoshop.png';
import premiereI from '../assets/premiere-pro.png';
import carlosI1 from '../assets/carlos 01.jpg';
import carlosI3 from '../assets/carlos 03.jpg';
import carlosI5 from '../assets/carlos 05.jpg';



export default function Inicio({ isTabletMobile }) {


    return (
        <>
            <div className='w-full flex flex-col justify-center items-center gap-4'>
                <div className='grid gap-4 grid-cols-1 w-full h-full'>
                    <div className='grid grid-cols-1 gap-4'>
                        <div className='card'>
                            <div className="">
                                <h1 className='text-base title-cinza mb-2'>hey,</h1>
                                <h1 className='text-2xl font-extrabold'>i'm carlos 👋🏼</h1>
                                <br></br>
                                <p>Five years ago, I decided to be proactive, and since then, I have been making unforgettable first impressions. After completing the course in Management and Programming of Information Systems, I decided to throw myself out of my comfort zone and began exploring the field of Web Development and Web Design on YouTube – the minutes viewed were deemed "astounding," which might surprise some readers. </p>
                                <br></br>
                                <p>Now, with over four years of experience as a young professional Front End Developer and Web Designer, I undertake institutional and e-commerce projects for small and medium-sized businesses. Therefore, I am living proof that if life inspires you to learn, you should "Google it."</p>
                            </div>
                            <div className="mt-8 flex flex-col gap-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="grid grid-cols-1 gap-7 h-80">
                                        <div className='h-40'>
                                            <img className="img-gallery" src={carlosI1} alt="OPENWALLET" />
                                        </div>
                                        <div className='h-40'>
                                            <img className="img-gallery" src={carlosI5} alt="OPENWALLET" />
                                        </div>
                                    </div>
                                    <div className="img-main-gallery">
                                        <img className="img-gallery" src={carlosI3} alt="OPENWALLET" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='w-full'>
                                <p className='text-base title-cinza mb-2'>main</p>
                                <h1 className='text-2xl font-extrabold'>technologies</h1>
                                <br></br>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    loop={true}
                                    className="mySwiper"
                                    // pagination={{
                                    //     clickable: true,
                                    // }}
                                    navigation={true}
                                    // modules={[Pagination, Navigation]}
                                    modules={[Autoplay, Navigation]}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 3,
                                        },
                                        840: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={reactI} alt="React" />
                                            <h1 className='text-sm font-normal'>React</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={javascriptI} alt="Javascript" />
                                            <h1 className='text-sm font-normal'>Javascript</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={typesenseI} alt="Typesense" />
                                            <h1 className='text-sm font-normal'>Typesense</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={algoliaI} alt="Algolia" />
                                            <h1 className='text-sm font-normal'>Algolia</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={htmlI} alt="HTML" />
                                            <h1 className='text-sm font-normal'>HTML</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={cssI} alt="CSS" />
                                            <h1 className='text-sm font-normal'>CSS</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={bootstrapI} alt="Bootstrap" />
                                            <h1 className='text-sm font-normal'>Bootstrap</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={wordpressI} alt="Wordpress" />
                                            <h1 className='text-sm font-normal'>Wordpress</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={elementorI} alt="Elementor" />
                                            <h1 className='text-sm font-normal'>Elementor</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={figmaI} alt="Figma" />
                                            <h1 className='text-sm font-normal'>Figma</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={photoshopI} alt="Photoshop" />
                                            <h1 className='text-sm font-normal'>Photoshop</h1>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <img className='img-tech' src={premiereI} alt="Premiere" />
                                            <h1 className='text-sm font-normal'>Premiere</h1>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='w-full'>
                                <p className='text-base title-cinza mb-2'>projects</p>
                                <h1 className='text-2xl font-extrabold'>hard work</h1>
                                <br></br>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    loop={true}
                                    className="mySwiper"
                                    // pagination={{
                                    //     clickable: true,
                                    // }}
                                    navigation={true}
                                    // modules={[Pagination, Navigation]}
                                    modules={[Autoplay, Navigation]}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        840: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                >
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <a href="https://www.behance.net/gallery/145169935/EMOTIONAL-OBJECTS-Luxury-Furniture-Website" target="_blank">
                                                <h1 className='text-md font-extrabold'>Emotional Objects</h1>
                                                <p>Luxury Furniture Website</p>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <a href="https://www.comoeonde.pt" target="_blank">
                                                <h1 className='text-md font-extrabold'>ComoeOnde</h1>
                                                <p>Forum Tips & Information</p>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <a href="https://www.behance.net/gallery/165232571/MECO-Clinic-Plataform-Website-Design" target="_blank">
                                                <h1 className='text-md font-extrabold'>Meco+</h1>
                                                <p>Clinic Plataform</p>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col items-center border-neutral-150 gap-4 rounded-lg border p-4 transition-colors hover:border-neutral-400 dark:border-neutral-800 dark:hover:border-neutral-600'>
                                            <a href="https://www.behance.net/gallery/160963887/DOCA-DA-MARINHA-LISBOA-Website-Design" target="_blank">
                                                <h1 className='text-md font-extrabold'>Doca da Marinha</h1>
                                                <p>Restaurants & Events</p>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}   