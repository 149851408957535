import React from 'react';
import carlosI from '../../assets/illu_carlos.png';



export default function Footer({ isTabletMobile }) {


    const date = new Date();
    const year = date.getFullYear();
    console.log(year);

    return (
        <div className="footer">

            <div className='flex flex-col gap-6'>
                {isTabletMobile ? (
                    // Responsive
                    <div className='text-center'>
                        <p className='text-xs'>© 2023 carloscostadev.</p>
                    </div>
                ) : (
                    // Normal
                    <>
                        <div className='card z-50'>
                            <div className='w-full'>
                                <ul className="flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-2 md:space-y-0 font-sm text-neutral-600 dark:text-neutral-300">
                                    <li>
                                        <a className="flex items-center hover:text-neutral-800 dark:hover:text-neutral-100 transition-all" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/carloscostaprogramador1999/">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z" fill="currentColor"></path></svg>
                                            <p className="ml-2">follow me</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="flex items-center hover:text-neutral-800 dark:hover:text-neutral-100 transition-all" rel="noopener noreferrer" target="_blank" href="mailto:carloscosta8d@gmail.com">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z" fill="currentColor"></path></svg>
                                            <p className="ml-2">contact</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='mt-5'>
                                <p className='text-xs'>© {year} carloscostadev.pt</p>
                            </div>
                        </div>
                        <div className='flex flex-col items-center gap-4 text-center'>
                            <div>
                                <img className='img-carlos' src={carlosI} alt="CarlosCosta" />
                            </div>
                        </div>
                    </>

                )}
            </div>

        </div>
    );
}
